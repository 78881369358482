/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
